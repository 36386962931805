/* eslint-disable no-useless-escape */
import {
  Component,
  AfterViewInit,
  Input,
  ElementRef,
  ViewChild,
  HostListener,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { copyToClipboard } from '@utility';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'cloudlabs-databricks-layout',
  templateUrl: './databricks-layout.component.html',
  styleUrls: ['./databricks-layout.component.scss'],
})
export class DatabricksLayoutComponent implements AfterViewInit {
  @ViewChild('iframe', { static: true }) iframe!: ElementRef<HTMLIFrameElement>;

  @Input() vCode: string;
  @Input() isDraggable: boolean;
  @Input() initialDeployment: any;
  @Input() htmlResponseforAWS: string;

  @HostListener('document:click', ['$event'])
  async handleClick($event) {
    if (
      $event.target.id === 'copyelement' ||
      ($event.target as HTMLElement).classList.contains('copyelement')
    ) {
      const brRegex = /<br\s*[\/]?>/gi;
      const copyData =
        $event.target.innerText || $event.target.firstChild.innerText;
      const txtdata = copyData.replace(brRegex, '\r\n');
      await copyToClipboard(txtdata);

      this.tostrSrv.success(
        '<p role="alert">' +
          this.translateSrv.instant('MESSAGE_COPIED') +
          '</p>',
        null,
        {
          enableHtml: true,
          timeOut: 3000,
        }
      );
    }
  }

  @HostListener('window:keydown', ['$event'])
  async onkeyPress($event) {
    if ($event.key == 'Enter' && $event.keyCode == 13) {
      if (
        $event.target.id === 'copyelement' ||
        ($event.target as HTMLElement).classList.contains('copyelement')
      ) {
        const brRegex = /<br\s*[\/]?>/gi;
        const copyData =
          $event.target.innerText || $event.target.firstChild.innerText;
        const txtdata = copyData.replace(brRegex, '\r\n');
        await copyToClipboard(txtdata);

        this.tostrSrv.success(
          '<p role="alert">' +
            this.translateSrv.instant('MESSAGE_COPIED') +
            '</p>',
          null,
          {
            enableHtml: true,
            timeOut: 3000,
          }
        );
      }
    }
  }

  constructor(
    private tostrSrv: ToastrService,
    private translateSrv: TranslateService
  ) {
    this.isDraggable = false;
  }

  ngAfterViewInit(): void {
    if (!this.htmlResponseforAWS) {
      const workSpaceLink =
        this.initialDeployment?.AllocatedTestDriveViewModalDetails[0]?.DeploymentOutputValues[0]?.OutputValues?.find(
          (x) => x.Name === 'Databricks Workspace URL'
        );
      setTimeout(() => {
        this.setIframeReady(workSpaceLink);
      }, 100);
    } else {
      setTimeout(() => {
        this.setIframeForAWS(this.htmlResponseforAWS);
      }, 100);
    }
  }

  private setIframeReady(workSpaceLink): void {
    const dbAuthentcateStatus = sessionStorage.getItem('dbAuthentcateStatus');
    if (!dbAuthentcateStatus) {
      sessionStorage.setItem('dbAuthentcateStatus', 'true');
    }
    this.iframe.nativeElement.src = workSpaceLink.Value;
  }

  private setIframeForAWS(iframeCode) {
    const win: Window = this.iframe.nativeElement.contentWindow;
    const doc: Document = win.document;
    doc.open();
    doc.write(iframeCode);
    doc.close();
  }
}
